import { defineStore } from 'pinia'
import type { ApplicationInfo, Beneficiary, ChildInsured, SpouseInsured } from '../types'
import nuxtStorage from 'nuxt-storage';

export const useApplicationInfoStore = defineStore('application-info', {

  state: () => ({
    applicationInfo: {} as ApplicationInfo,
    caseDecision: {} as Object
  }),
  persist: true,
  actions: {
    setApplicationInfoValue (value: ApplicationInfo) {
      this.applicationInfo = value
    },
    setCaseDecisionValue (value: Object) {
      this.caseDecision = value
    },
    addChildInsured(newChild: ChildInsured) {
      this.applicationInfo.product.childInsureds.push(newChild);
    },
    updateChildInsured(child: ChildInsured, index: number) {
      this.applicationInfo.product.childInsureds[index] = child
    },
    deleteChildInsured(index: number) {
      this.applicationInfo.product.childInsureds.splice(index, 1)
    },
    setchildInsureds(child: Array<ChildInsured>) {
      this.applicationInfo.product.childInsureds = child
    },
    setSpouseInsured(spouse: SpouseInsured) {
      this.applicationInfo.product.spouseInsured = spouse
    },

    addBeneficiary(beneficiary: Beneficiary) {
      this.applicationInfo.product.beneficiaries.primaryBeneficiaries.push(beneficiary);
    },
    updateBeneficiary(beneficiary: Beneficiary, index: number) {
      this.applicationInfo.product.beneficiaries.primaryBeneficiaries[index] = beneficiary
    },
    deleteBeneficiary(index: number) {
      this.applicationInfo.product.beneficiaries.primaryBeneficiaries.splice(index, 1)
    },
    reset() {
      this.applicationInfo = {} as ApplicationInfo
      this.caseDecision = {} as Object
      nuxtStorage?.sessionStorage?.removeItem('application-info');
    },
  },
  getters: {
    caseId: state => state.applicationInfo.caseId,
    documents: state => state.applicationInfo.documents,
    payment: state => state.applicationInfo.payment,
    primaryInsuranceAgent: state => state.applicationInfo.primaryInsuranceAgent,
    product: state => state.applicationInfo.product,
    signedInformation: state => state.applicationInfo.signedInformation,
    experience: state => state.applicationInfo.experience
  }
})
