import { ref } from 'vue'
import type { InsuranceAgent } from '~/types';
import type { ApplicationInfo } from '~/types/application-info'

export function useApplicationInfo() {
  const instance = useRuntimeConfig().public.instanceName;
  const primaryInsuranceAgent = ref<InsuranceAgent>({} as InsuranceAgent);
  const splitInsuranceAgents = ref<InsuranceAgent[]>([] as InsuranceAgent[]);
  let envPrimaryInsuranceAgent = useRuntimeConfig().public.primaryInsuranceAgent
  if(isProxy(envPrimaryInsuranceAgent)) {
    envPrimaryInsuranceAgent = toRaw(envPrimaryInsuranceAgent);
  }
  let envSplitInsuranceAgents = useRuntimeConfig().public.splitInsuranceAgents
  if(isProxy(envSplitInsuranceAgents)) {
    envSplitInsuranceAgents = toRaw(envSplitInsuranceAgents);
  }
  primaryInsuranceAgent.value = {
    commissionPercentage: 100,
    emailAddress: 'rosesway58@gmail.com',
    identifier: '63TW',
    name: {
      first: 'Rosemarie',
      last: 'Thompson',
      middle: '',
      suffix: ''
    }
  }
  const agentInfo = toRaw(useAgentInfoStore().insuranceAgent)
  const isSplitAgent = useAgentInfoStore().isSplitAgent
  
  if (envPrimaryInsuranceAgent) {
    try {
      primaryInsuranceAgent.value = JSON.parse(JSON.stringify(envPrimaryInsuranceAgent)) as InsuranceAgent;
    } catch (error) {     
      console.error('Invalid JSON string - primaryInsuranceAgent:', error)
    }
  }
  if(agentInfo?.identifier) {
    try {
      if(isSplitAgent) {
        console.log("agentInfo-isSplitAgent: ", agentInfo, primaryInsuranceAgent.value)
        setDeepValue(primaryInsuranceAgent.value, 'commissionPercentage', (100-_get(agentInfo, "commissionPercentage", 0)))
        splitInsuranceAgents.value = [toRaw(primaryInsuranceAgent.value)]
        primaryInsuranceAgent.value = agentInfo
        console.log("agentInfo-primaryInsuranceAgent: ", primaryInsuranceAgent.value, splitInsuranceAgents.value)
      } else {
        primaryInsuranceAgent.value = agentInfo
        setDeepValue(primaryInsuranceAgent.value, 'commissionPercentage', 100)
      }
    } catch (error) {     
      console.error('Invalid JSON string - splitInsuranceAgents:', error)
    }
  } else {
    useAgentInfoStore().reset();
  }
  
  const applicationInfo = ref<ApplicationInfo>({
    caseId: '',
    envelopeId: '',
    documents: [
      {
        base64Content: '',
        documentType: 'MIB_PRENOTICE',
        name: ''
      }
    ],
    payment: {
      automaticBankWithdrawal: {
        bank: {
          accountNumber: null,
          accountType: null,
          routingNumber: null,
          financialInstitutionName: '',
        },
        paymentFrequency: 'Monthly',
        dayOfCharge: null,
        draftIncludesInitialPayment: true,
      },
      automaticCreditCard: {
        paymentFrequency: 'Monthly',
        dayOfCharge: null,
        draftIncludesInitialPayment: true,
        /*ftniPayment: {
          customerIdentifier: null
        },*/
        stripePayment: {
          cardTokenId: null,
          emailAddress: null
        }
      },
      directBilling: {
        paymentFrequency: 'Monthly'
      },
      listBilling: {
        listBillIdentifier: null,
        paymentFrequency: 'Monthly'
      }
    },
    primaryInsuranceAgent: primaryInsuranceAgent.value,
    product: {
      benefitPackage: {
        applicationValue: 'Complete'
      },
      issueStateAbbreviation: '',
      payors: {
        primaryPayor: {
          address: {
            city: '',
            line1: '',
            stateAbbreviation: '',
            zipCode: '',
            country: '',
            line2: '',
            line3: '',
            line4: ''
          },
          business: {
            name: '',
            taxIdNumber: ''
          },
          person: {
            name: {
              first: '',
              last: '',
              middle: '',
              suffix: ''
            },
            dateOfBirth: null,
            gender: '',
            socialSecurityNumber: ''
          },
          phone: {
            areaCode: '',
            number: ''
          }
        },
        additionalPayor: {
          address: {
            city: '',
            line1: '',
            stateAbbreviation: '',
            zipCode: '',
            country: '',
            line2: '',
            line3: '',
            line4: ''
          },
          business: {
            name: '',
            taxIdNumber: ''
          },
          person: {
            name: {
              first: '',
              last: '',
              middle: '',
              suffix: ''
            },
            dateOfBirth: null,
            gender: '',
            socialSecurityNumber: ''
          },
          phone: {
            areaCode: '',
            number: ''
          }
        },
        secondaryAddresseePayor: {
          address: {
            city: '',
            line1: '',
            stateAbbreviation: '',
            zipCode: '',
            country: '',
            line2: '',
            line3: '',
            line4: ''
          },
          business: {
            name: '',
            taxIdNumber: ''
          },
          person: {
            name: {
              first: '',
              last: '',
              middle: '',
              suffix: ''
            },
            dateOfBirth: null,
            gender: '',
            socialSecurityNumber: ''
          },
          phone: {
            areaCode: '',
            number: ''
          }
        }
      },
      primaryInsured: {
        address: {
          city: '',
          country: 'USA',
          line1: '',
          stateAbbreviation: '',
          zipCode: '',
          line2: '',
          line3: '',
          line4: ''
        },
        coverages: [
          {
            coverageLookup: 'Base',
          },
          {
            coverageLookup: 'AccidentalDeathBenefit'
          }
        ],
        person: {
          dateOfBirth: null,
          gender: '',
          name: {
            first: '',
            last: '',
            middle: '',
            suffix: ''
          },
          socialSecurityNumber: '',
          emailAddress: ''
        },
        phone: {
          areaCode: '',
          number: ''
        }
      },
      insuredOption: {
        applicationValue: null
      },
      coverageType: {
        applicationValue: 'TwentyFourHour'
      },
      beneficiaries: {
        primaryBeneficiaries: [],
        contingentBeneficiaries: []
      },
      owners: {
        primaryOwner: {
          address: {
            city: '',
            country: '',
            line1: '',
            stateAbbreviation: '',
            zipCode: '',
            line2: '',
            line3: '',
            line4: ''
          },
          business: {
            name: '',
            taxIdNumber: ''
          },
          person: {
            dateOfBirth: null,
            name: {
              first: '',
              last: '',
              middle: '',
              suffix: ''
            },
            socialSecurityNumber: '',
            emailAddress: ''
          },
          phone: {
            areaCode: '',
            number: ''
          }
        }
      },
      spouseInsured: {
        person: {
          dateOfBirth: null,
          gender: '',
          name: {
            first: '',
            last: '',
            middle: '',
            suffix: ''
          },
          socialSecurityNumber: '',
          emailAddress: ''
        }
      },
      childInsureds: []
    },
    signedInformation: {
      date: new Date().toISOString(),
      location: {
        stateAbbreviation: ''
      }
    },
    experience: {
      type: ''
    }
  })
  if(splitInsuranceAgents.value) {
    applicationInfo.value.splitInsuranceAgents = splitInsuranceAgents.value
  }
  return {
    applicationInfo
  }
}