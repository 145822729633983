import { ref } from 'vue'
import { useFullUrl } from '@/composables/useFullUrl'

export function useContactInfo() {
  const contactInfo = ref<ContactInfo>({
    properties: {
      firstname: '',
      lastname: '',
      suffix: '',
      email: '',
      phone: '',
      instance: useRuntimeConfig().public.instanceName,
      last_step_in_assurity: '',
      assurity_case_id: '',
      agent_url_slug: '',
      company_url_slug: '',
      assurity_policy_number: '',
      age: 0,
      state: '',
      coverage_choice: '',
      monthly_premium_emerge_app: 0,
      date_of_birth_emerge: '',
      source_url: '',
    },
  })

  async function getContactInfo(email: string) {
    try{

      const response = await fetch(`/webapi/contacts/email/${email}`, {
        method: 'GET',
      })

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const contactData = await response.json() as ContactInfo;
      if(contactData?.id) {
        return contactData;
      } else {
        throw createError({
          statusCode: 400,
          statusMessage: `Request Error: Not Found}`,
        })
      }
    } catch(error: any) {
      throw createError({
        statusCode: 500,
        statusMessage: `Request Error: ${error.message.toString()}`,
      })
    }
  }

  async function postContactInfo(step: string) {
    const _contactInfoForPost = ref(_cloneDeep(useContactInfoStore().contactInfo))
    setDeepValue(_contactInfoForPost.value, "properties.last_step_in_assurity", step)

    //GET AND UPDATE SOURCE URL BEFORE UPDATING CONTACT
    const { getCurrentUrl } = useFullUrl()
    const currentUrl = getCurrentUrl()
    setDeepValue(_contactInfoForPost.value, "properties.source_url", currentUrl)
    try{
      const params = { properties: _contactInfoForPost.value.properties }
      const response = await fetch(`/webapi/contacts`, {
        method: 'POST',
        body: JSON.stringify(params),
      })

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const responseData = await response.json() as ContactInfo
      
      if(responseData?.id) {
        setDeepValue(_contactInfoForPost.value, 'id', responseData?.id)
        useContactInfoStore().setContactInfoValue(_contactInfoForPost.value)
      } else {
        throw createError({
          statusCode: 404,
          statusMessage: `Request Error: Contact Not Found`,
        })
      }
    } catch(error: any) {
      throw createError({
        statusCode: 500,
        statusMessage: `Request Error: ${error.message.toString()}`,
      })
    }
  }

  async function putContactInfo(step: string) {
    const _contactInfoForPut = ref(_cloneDeep(useContactInfoStore().contactInfo))
    setDeepValue(_contactInfoForPut.value, "properties.last_step_in_assurity", step)

    //GET AND UPDATE SOURCE URL BEFORE UPDATING CONTACT
    const { getCurrentUrl } = useFullUrl()
    const currentUrl = getCurrentUrl()
    setDeepValue(_contactInfoForPut.value, "properties.source_url", currentUrl)
    try{
      const id = _contactInfoForPut.value.id
      const response = await fetch(`/webapi/contacts/${id}`, {
        method: 'PUT',
        body: JSON.stringify(_contactInfoForPut.value),
      })

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const responseData = await response.json() as ContactInfo
      
      if(responseData?.id) {
        setDeepValue(_contactInfoForPut.value, 'id', responseData?.id)
        useContactInfoStore().setContactInfoValue(_contactInfoForPut.value)
      }
    } catch(error: any) {
      throw createError({
        statusCode: 500,
        statusMessage: `Request Error: ${error.message.toString()}`,
      })
    }
  }
  return { 
    contactInfo,
    getContactInfo,
    postContactInfo,
    putContactInfo
  }
}