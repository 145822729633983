import { useRoute } from '#imports';

export function useFullUrl() {
  const route = useRoute()

  const getCurrentUrl = (): string => {
    const protocol = window?.location?.protocol
    const host = window?.location?.host
    const path = route?.fullPath
    return `${protocol}//${host}${path}`
  }

  return { getCurrentUrl }
}
