import { useDate } from 'vuetify';

export function generateId () {
  return crypto.randomUUID()
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const formatCurrency = (value: number) => {
  return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export function setDeepValue(obj: Record<string, any>, path: string, value: any): void {
  const keys = path.split('.');
  let current = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    if (!current[keys[i]]) {
      current[keys[i]] = {};
    }
    current = current[keys[i]];
  }

  current[keys[keys.length - 1]] = value;
}

export function isValidDate(dateString: string): boolean {
  // Check if the input string matches the mm/dd/yyyy date format
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!regex.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  const parts = dateString.split("/");
  const month = parseInt(parts[0], 10);
  const day = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  // Check if the date parts are valid
  if (year < 1000 || year > 9999 || month < 1 || month > 12) {
    return false;
  }

  // Create a Date object from the parts
  const date = new Date(year, month - 1, day);

  // Check if the day is valid for the month
  if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
    return false;
  }

  return true;
}

export function customDateFormat(dateString: string): string {
  const dateCompose = useDate()
  return dateCompose.format(new Date(dateString), "keyboardDate")
}

export function validateSSN(ssn: string): boolean {
  // Regular expression for validating SSN
  //const pattern = /^(?!666|000|9\d\d)\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
  const pattern = /^\d{3}-\d{2}-\d{4}$/;

  // Check if the input matches the SSN format
  if (!pattern.test(ssn)) {
    return false;
  }

  // Additional validation rules can be added here if needed
  return true;
}

export function validateEmail(email: string): boolean {
  // Regular expression for validating email address
  //const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the input matches the email format
  return pattern.test(email);
}

export function validatePhoneNumber(phoneNumber: string): boolean {
  // Regular expression for validating phone numbers (US format)
  const pattern = /^\(\d{3}\) \d{3}-\d{4}$/;

  // Check if the input matches the phone number format
  return pattern.test(phoneNumber);
}

export function validateZipCode(zipCode: string): boolean {
  // Regular expression for validating ZIP codes (US format)
  const pattern = /^\d{5}(-\d{4})?$/;

  // Check if the input matches the ZIP code format
  return pattern.test(zipCode);
}





export const getCalculatedAge = (birthDate: Date) => {
  const today = new Date()
  const age = ref(today.getFullYear() - birthDate.getFullYear())
  const monthDiff = today.getMonth() - birthDate.getMonth()
  const dayDiff = today.getDate() - birthDate.getDate()
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && dayDiff < 0)
  ) {
    age.value--
  }
  return age.value
}
